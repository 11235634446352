<template>
  <router-view />
</template>

<script>
  export default {
    data () {
      return {}
    },
  }
</script>

<style lang="scss" scoped>
</style>
